import { useGetDayOffsByMonthQuery } from "@/api/dayOffApi";
import { useGetDoctorsQuery } from "@/api/doctorApi";
import { useGetCalendarQuery } from "@/api/locationApi";
import { useGetSchedulesByDoctorQuery } from "@/api/rosterApi";
import { useGetShiftsByMonthQuery } from "@/api/shiftsApi";
import { TIME_ZONE } from "@/constants";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import { Doctor } from "@/interface/doctor";
import { useSeniority } from "@/store/seniority.state";
import { decrementMonth, incrementMonth } from "@/utils/date";
import { DateTime } from "luxon";
import { useCallback } from "react";

export const useDoctors = () => {
  const { activeId: activeSeniorityId } = useSeniority();
  const { doctorSort, searchQuery } = useTableViewContext();
  const { data, isLoading, isFetching, refetch } = useGetDoctorsQuery({
    seniority: 4,
  });

  return {
    doctors: data?.doctors
      .filter((doctor: Doctor) => {
        return (
          (!activeSeniorityId || doctor.seniority.id === activeSeniorityId) &&
          (searchQuery === "" ||
            doctor.user.name
              .toLowerCase()
              .startsWith(searchQuery.toLowerCase()))
        );
      })
      .sort((a: Doctor, b: Doctor) => {
        return (
          (doctorSort === "asc" ? 1 : -1) *
          a.user.name.localeCompare(b.user.name)
        );
      }),
    isLoading,
    isFetching,
    refetch,
  };
};

export const useTableData = ({
  month,
  year,
  activeLocationId,
  activeSeniorityId,
  type,
}: {
  month: number;
  year: number;
  activeLocationId?: string;
  activeSeniorityId?: number;
  type: "decrement" | "increment";
}) => {
  const {
    isLoading: isCalendarLoading,
    isFetching: isCalendarFetching,
    data: calendarData,
  } = useGetCalendarQuery(
    {
      locationId: activeLocationId?.toString(),
      seniority: Number(activeSeniorityId),
      month: month,
      year,
    },
    { skip: !activeLocationId || !activeSeniorityId }
  );

  const {
    isLoading: isCalendarLoadingOther,
    isFetching: isCalendarFetchingOther,
    data: calendarDataOther,
  } = useGetCalendarQuery(
    {
      locationId: activeLocationId?.toString(),
      seniority: Number(activeSeniorityId),
      month: month,
      year,
    },
    { skip: !activeLocationId || !activeSeniorityId }
  );

  const {
    data: schedulesByDoctor,
    isLoading: isSchedulesLoading,
    isFetching: isSchedulesFetching,
  } = useGetSchedulesByDoctorQuery({ month, year });

  const {
    data: shiftsByMonth,
    isLoading: isShiftsByMonthLoading,
    isFetching: isShiftsByMonthFetching,
  } = useGetShiftsByMonthQuery({ month, year });

  const {
    data: dayOffsByMonth,
    isLoading: isDayOffsLoading,
    isFetching: isDayOffsFetching,
  } = useGetDayOffsByMonthQuery({ month, year });

  const otherMonth =
    type === "increment"
      ? incrementMonth(month, year)
      : decrementMonth(month, year);

  const {
    data: schedulesByDoctorOther,
    isLoading: isSchedulesLoadingOther,
    isFetching: isSchedulesFetchingOther,
  } = useGetSchedulesByDoctorQuery(otherMonth);

  const {
    data: shiftsByMonthOther,
    isLoading: isShiftsByMonthLoadingOther,
    isFetching: isShiftsByMonthFetchingOther,
  } = useGetShiftsByMonthQuery(otherMonth);

  const {
    data: dayOffsByMonthOther,
    isLoading: isDayOffsLoadingOther,
    isFetching: isDayOffsFetchingOther,
  } = useGetDayOffsByMonthQuery(otherMonth);

  return {
    isTableDataLoading:
      isSchedulesLoading ||
      isShiftsByMonthLoading ||
      isDayOffsLoading ||
      isSchedulesLoadingOther ||
      isShiftsByMonthLoadingOther ||
      isCalendarLoading ||
      isCalendarLoadingOther ||
      isDayOffsLoadingOther,
    isTableDataFetching:
      isSchedulesFetching ||
      isShiftsByMonthFetching ||
      isDayOffsFetching ||
      isSchedulesFetchingOther ||
      isShiftsByMonthFetchingOther ||
      isDayOffsFetchingOther ||
      isCalendarFetching ||
      isCalendarFetchingOther,
    shiftsByMonth:
      shiftsByMonth && shiftsByMonthOther
        ? { ...shiftsByMonth, ...shiftsByMonthOther }
        : undefined,
    schedulesByDoctor:
      schedulesByDoctor && schedulesByDoctorOther
        ? {
            [`${month}-${year}`]: schedulesByDoctor,
            [`${otherMonth.month}-${otherMonth.year}`]: schedulesByDoctorOther,
          }
        : undefined,
    dayOffsByMonth:
      dayOffsByMonth && dayOffsByMonthOther
        ? { ...dayOffsByMonth, ...dayOffsByMonthOther }
        : undefined,
    calendar:
      calendarData && calendarDataOther
        ? Object.fromEntries(
            [...calendarData.calendar, ...calendarDataOther.calendar].map(
              (item: any) => [item.date, item]
            )
          )
        : undefined,
  };
};
