import { InfinitySpin } from "react-loader-spinner";

import { useGetLocationsQuery } from "@/api/locationApi";

import TableViewLayout from "@/layouts/TableView";
import { TableViewContextProvider } from "@/context/TableViewContext";

import TopNavBar from "@/components/TableView/TopNavBar";
import SideBar from "@/components/TableView/SideBar";
import Table from "@/components/TableView/Table";
import MenuOptions from "@/components/TableView/MenuOptions";
const TableView = () => {
  const { isLoading: isLocationLoading } = useGetLocationsQuery({
    seniority: 4,
    all: true,
  });

  return (
    <TableViewContextProvider>
      <TableViewLayout>
        {isLocationLoading ? (
          <InfinitySpin width="200px" color="#67823A" />
        ) : (
          <>
            <div className="flex w-full">
              <div className="w-[17%]">
                <MenuOptions />
              </div>
              <div className="w-[83%]">
                <TopNavBar />
              </div>
            </div>
            <div className="flex flex-grow w-full">
              <div className="w-[17%]">
                <SideBar />
              </div>
              <div className="w-[83%] pr-5 bg-gray4">
                <Table />
              </div>
            </div>
          </>
        )}
      </TableViewLayout>
    </TableViewContextProvider>
  );
};

export default TableView;
