import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

import navBarBackground from "@/assets/images/BackgroundDetail2.png";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import { TIME_ZONE } from "@/constants";

import Table from "../Icons/Table";
import { useSeniority } from "@/store/seniority.state";
import CarotWide from "../Icons/CarotWide";
import { decrementMonth, incrementMonth } from "@/utils/date";

const LABEL_WIDTHS: Record<string, number> = {
  "1": 100,
  "2": 100,
  "3": 100,
  "4": 100,
  "5": 100,
  "6": 100,
  "7": 100,
  "8": 100,
  "9": 100,
  "10": 100,
  "11": 100,
  "12": 100,
};

const MonthLabel = ({
  month,
  year,
  nextBackHandlers,
}: {
  month: number;
  year: number;
  nextBackHandlers?: { next: () => void; back: () => void };
}) => {
  const dateTime = DateTime.fromObject({ year, month }, { zone: TIME_ZONE });

  return (
    <div className="flex w-full h-full items-center gap-1">
      <div className="w-fit bg-white h-fit rounded drop-shadow-lg py-2">
        <div
          className={`text-xl ${
            dateTime.month % 2 === 0 ? "text-pink1" : "text-maroon4"
          } font-extrabold vertical-text -scale-100 h-fit`}
        >
          {dateTime.toFormat("yyyy")}
        </div>
      </div>
      <div className={"flex gap-[2px]"}>
        {nextBackHandlers && (
          <div
            className="group bg-white flex items-center px-[2px] cursor-pointer rounded-l-lg drop-shadow-lg"
            onClick={nextBackHandlers.back}
          >
            <CarotWide
              type="left"
              pathFill={"#CFB0A8"}
              classPathFill={
                dateTime.month % 2 === 0
                  ? "group-hover:fill-pink1"
                  : "group-hover:fill-maroon4"
              }
            />
          </div>
        )}
        <div
          className={`w-fit text-7xl bg-white ${
            dateTime.month % 2 === 0 ? "text-pink1" : "text-maroon4"
          } ${
            !nextBackHandlers ? "rounded-lg" : ""
          } font-black px-2 h-full drop-shadow-lg`}
        >
          {dateTime.toFormat("LLLL")}
        </div>
        {nextBackHandlers && (
          <div
            className="group bg-white flex items-center px-[2px] cursor-pointer rounded-r-lg drop-shadow-lg"
            onClick={nextBackHandlers.next}
          >
            <CarotWide
              type="right"
              pathFill={"#CFB0A8"}
              classPathFill={
                dateTime.month % 2 === 0
                  ? "group-hover:fill-pink1"
                  : "group-hover:fill-maroon4"
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

const TopNavBar = () => {
  const {
    month,
    year,
    calenderBack,
    calenderNext,
    resetFilters,
    setTableParams,
    monthLabel,
    monthRef,
  } = useTableViewContext();
  const { activeId: activeSeniorityId, setActiveId } = useSeniority();

  const navigate = useNavigate();

  const prevMonth = decrementMonth(month, year);
  const nextMonth = incrementMonth(month, year);

  return (
    <div className="w-full h-[100px] bg-gray4 relative p-2">
      <div
        className="w-full h-full opacity-10 bg-[length:1000px_500px] absolute top-0 left-0"
        style={{
          backgroundImage: `url(${navBarBackground})`,
        }}
      ></div>
      <div className="h-[80px] flex items-center justify-between gap-2 relative z-10 overflow-hidden">
        <div
          className={`absolute left-1 ${
            !monthLabel.stationary.show ? "hidden" : ""
          }`}
          ref={(ref) => {
            monthRef!.current.stationary = ref;
          }}
        >
          <MonthLabel
            month={
              monthLabel.stationary.type === "prev" ? prevMonth.month : month
            }
            year={monthLabel.stationary.type === "prev" ? prevMonth.year : year}
            nextBackHandlers={{
              next: () => {
                const { month, year } = calenderNext();
                resetFilters();
                setTableParams({ month_: month, year_: year });
              },
              back: () => {
                const { month, year } = calenderBack();
                resetFilters();
                setTableParams({ month_: month, year_: year });
              },
            }}
          />
        </div>
        <div
          ref={(ref) => {
            monthRef!.current.animated = ref;
          }}
          className={`absolute ${!monthLabel.animated.show ? "hidden" : ""}`}
        >
          <MonthLabel
            month={
              monthLabel.animated.type === "current" ? month : nextMonth.month
            }
            year={
              monthLabel.animated.type === "current" ? year : nextMonth.year
            }
            {...(monthLabel.animated.hasArrows
              ? {
                  nextBackHandlers: {
                    next: () => {
                      const { month, year } = calenderNext();
                      resetFilters();
                      setTableParams({ month_: month, year_: year });
                    },
                    back: () => {
                      const { month, year } = calenderBack();
                      resetFilters();
                      setTableParams({ month_: month, year_: year });
                    },
                  },
                }
              : {})}
          />
        </div>
        <div
          className="cursor-pointer absolute right-0"
          onClick={() => {
            if (!activeSeniorityId) {
              setActiveId(1);
            }
            navigate("/dashboard");
          }}
        >
          <Table isActive={true} />
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;
