import { useGetLocationsQuery } from "@/api/locationApi";
import { useLocation } from "@/store/location.store";
import { useState } from "react";
import CarotTwo from "../Icons/CarotTwo";
import ClockThree from "../Icons/ClockThree";
import UserRequest from "../Icons/UserRequest";
import Location from "../Icons/Location";
import User from "../Icons/User";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";

const MenuOptions = () => {
  const { data: locationsData } = useGetLocationsQuery({
    seniority: 4,
    all: true,
  });

  const {
    activeId: activeLocationId,
    setActiveLocation,
    activeLocation,
  } = useLocation();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const {
    tableState,
    setTableState,
    isDayOffFilter,
    setIsDayOffFilter,
    isShiftTimeToggle,
    setIsShiftTimeToggle,
    isLocationFilter,
    setIsLocationFilter,
    setTableParams,
  } = useTableViewContext();

  return (
    <div className="w-full h-[100px] py-2 pr-2 flex justify-between items-center bg-gray4">
      <div className="flex gap-1 h-[60px]">
        <div
          className="rounded-r-xl bg-green11 flex items-center relative transition-[width] duration-500 shadow-xl"
          style={{ width: isDrawerOpen ? "140px" : "60px" }}
        >
          <div className="rounded-r-xl bg-green12 w-[50px] h-full flex justify-center items-center text-white font-extrabold text-base">
            {activeLocation ? activeLocation.shortLabel : "ALL"}
          </div>
          {isDrawerOpen ? (
            <div className="grid grid-rows-2 grid-flow-col p-1 h-[90%] gap-1">
              {locationsData?.locations.map(
                (location: { shortLabel: string; _id: string }) => (
                  <div
                    key={location._id}
                    className={`row-span-1 ${
                      location._id === activeLocation?._id
                        ? "bg-green12 text-white"
                        : "bg-white text-green12"
                    } rounded-lg w-fit h-fit text-[8px] font-medium px-1 py-1 cursor-pointer`}
                    onClick={() => {
                      if (activeLocationId !== location._id) {
                        setActiveLocation(location);
                      }
                    }}
                  >
                    {location.shortLabel}
                  </div>
                )
              )}
              <div
                key={"all"}
                className={`row-span-1 ${
                  !activeLocation
                    ? "bg-green12 text-white"
                    : "bg-white text-green12"
                } rounded-lg w-fit h-fit text-[8px] font-medium px-1 py-1 cursor-pointer`}
                onClick={() => {
                  if (activeLocation) {
                    setActiveLocation(undefined);
                    setIsLocationFilter(true);
                    setIsDayOffFilter(true);
                    setTableParams({
                      isLocationFilter_: true,
                      locationId: null,
                    });
                  }
                }}
              >
                ALL
              </div>
            </div>
          ) : (
            <></>
          )}
          <div
            className="absolute right-0 cursor-pointer"
            onClick={() => {
              setIsDrawerOpen(!isDrawerOpen);
            }}
          >
            <CarotTwo
              type={isDrawerOpen ? "left" : "right"}
              pathFill={"#1D1B20"}
              size={{ height: "10px", width: "8px" }}
            />
          </div>
        </div>
        {!isDrawerOpen && (
          <>
            <div
              className={`${
                !activeLocation && isLocationFilter
                  ? "bg-green12 text-white"
                  : "bg-white text-green12"
              } w-[35px] rounded-lg shadow-xl flex items-center justify-center cursor-pointer`}
              onClick={() => {
                if (activeLocationId === "" && isDayOffFilter) {
                  setIsLocationFilter(!isLocationFilter);
                  setTableParams({ isLocationFilter_: !isLocationFilter });
                } else {
                  setActiveLocation(undefined);
                  setIsLocationFilter(true);
                  setTableParams({
                    isLocationFilter_: true,
                    locationId: null,
                  });
                }
              }}
            >
              <div>
                <div className="text-center font-semibold text-sm">ALL</div>
                <div className="text-center font-medium text-[8px]">ZONES</div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div
                className={`${
                  isDayOffFilter ? "bg-green12" : "bg-white"
                } w-[35px] rounded-lg shadow-xl flex items-center justify-center cursor-pointer`}
                onClick={() => {
                  if (isLocationFilter) {
                    setIsDayOffFilter(!isDayOffFilter);
                    setTableParams({
                      isDayOffFilter_: !isDayOffFilter,
                    });
                  }
                }}
              >
                <div
                  className={`${
                    isDayOffFilter ? "text-white" : "text-green12"
                  } font-semibold text-sm rounded-lg`}
                >
                  Off
                </div>
              </div>
              <div
                className={`${
                  isShiftTimeToggle ? "bg-green12" : "bg-white"
                } w-[35px] rounded-lg shadow-xl flex-grow flex items-center cursor-pointer`}
                onClick={() => {
                  setIsShiftTimeToggle(!isShiftTimeToggle);
                  setTableParams({
                    isShiftTimeToggle_: !isShiftTimeToggle,
                  });
                }}
              >
                <div className="w-full text-center flex flex-col gap-[2px]">
                  <div className="mx-auto w-fit">
                    <ClockThree isActive={isShiftTimeToggle} />
                  </div>
                  <div
                    className={`${
                      isShiftTimeToggle ? "text-white" : "text-green12"
                    } font-medium text-[6px] w-full`}
                  >
                    TOGGLE
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex h-[85px] items-center gap-1">
        <div className="flex flex-col gap-1 justify-center items-center w-[60px]">
          <div
            className={`bg-lightGreen4 shadow-xl rounded-xl h-[41px] w-[45px] flex justify-center items-center`}
          >
            {
              {
                users: (
                  <User
                    pathFill={"#FFFEF8"}
                    size={{ height: "35", width: "25" }}
                  />
                ),
                location: (
                  <Location
                    pathFill={"#FFFEF8"}
                    size={{ height: "28", width: "20" }}
                  />
                ),
                requests: (
                  <UserRequest
                    pathFill={"#FFFEF8"}
                    size={{ height: "35", width: "25" }}
                  />
                ),
              }[tableState]
            }
          </div>{" "}
          <div
            className={`text-white bg-lightGreen4 font-[450] text-[12px] text-center rounded-lg px-1 w-fit`}
          >
            {
              {
                users: "Users",
                location: "Location",
                requests: "Requests",
              }[tableState]
            }
          </div>{" "}
        </div>
        <div className="flex flex-col gap-3">
          {tableState !== "users" && (
            <div
              className={`bg-white shadow-xl rounded-lg h-[26px] w-[28px] flex justify-center items-center cursor-pointer`}
              onClick={() => {
                setTableState("users");
                setTableParams({
                  tableState_: "users",
                });
              }}
            >
              <User pathFill={"#71928D"} />
            </div>
          )}
          {tableState !== "location" && (
            <div
              className={`bg-white shadow-xl rounded-lg h-[26px] w-[28px] flex justify-center items-center cursor-pointer`}
              onClick={() => {
                setTableState("location");
                setTableParams({
                  tableState_: "location",
                });
              }}
            >
              <Location pathFill={"#71928D"} />
            </div>
          )}
          {tableState !== "requests" && (
            <div
              className={`bg-white shadow-xl rounded-lg h-[26px] w-[28px] flex justify-center items-center cursor-pointer`}
              onClick={() => {
                setTableState("requests");
                setTableParams({
                  tableState_: "requests",
                });
              }}
            >
              <UserRequest pathFill={"#71928D"} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuOptions;
