import { Key } from "./components/Dashboard/DayView";
import { ShiftType } from "./interface/shift";

export const TIME_ZONE = "Asia/Kolkata";
export const DISPLAY_DATE_FORMAT = "ccc, dd MMM";

export const DISPLAY_DATE_FORMAT_LONG = "ccc, MMMM yyyy";
export const API_DATE_FORMAT = "dd/MM/yyyy";
export const API_TIME_FORMAT = "h:mm a";

export const DATE_RANGE = 1;

export const CACHE_LIFE = 4 * 3600;

export const SENIORITIES = [
  {
    id: 1,
    colorCode: "#E57041",
    textColor: "text-[#E57041]",
    label: "Senior",
    shortLabel: "Sr",
    bgColor: "bg-[#ffdebb] text-[#df9c28]",
    countColor: "bg-[#df9c28] text-[#ffdebb]",
    activeColor: "bg-[#df9c28] text-white ",
    activeCountColor: "bg-[#FFDEBB] text-[#df9c28]",
  },
  {
    id: 2,
    colorCode: "#84ACE2",
    textColor: "text-[#84ACE2]",
    label: "Mid Level",
    shortLabel: "Mid",
    bgColor: "bg-[#ffdebb] text-[#df9c28]",
    countColor: "bg-[#df9c28] text-[#ffdebb]",
    activeColor: "bg-[#df9c28] text-white ",
    activeCountColor: "bg-[#FFDEBB] text-[#df9c28]",
  },
  {
    id: 3,
    colorCode: "#DF9C28",
    textColor: "text-[#DF9C28]",
    label: "Junior",
    shortLabel: "Jr",
    bgColor: "bg-[#ffdebb] text-[#df9c28]",
    countColor: "bg-[#df9c28] text-[#ffdebb]",
    activeColor: "bg-[#df9c28] text-white ",
    activeCountColor: "bg-[#FFDEBB] text-[#df9c28]",
  },
];

export const BG_SENIORITY = Object.fromEntries(
  SENIORITIES.map((seniority) => {
    return [seniority.id, `bg-[${seniority.colorCode}]`];
  })
);

export const GROUP_COLORS: Record<
  Key,
  {
    bgColor: string;
    bgColor2: string;
    bgColor3: string;
    bgColor4: string;
    bgColor5: string;
    bgColor6: string;
    bgColor7: string;
    bgColor8: string;
    textColor: string;
    textColor2: string;
    textColor3: string;
    textColor4: string;
    borderColor: string;
  }
> = {
  morning: {
    bgColor: "bg-green6",
    bgColor2: "bg-green8",
    bgColor3: "bg-yellow3",
    bgColor4: "bg-yellow2",
    bgColor5: "bg-yellow3",
    bgColor6: "bg-yellow4",
    bgColor7: "bg-yellow5",
    bgColor8: "bg-brown",
    textColor: "text-green7",
    textColor2: "text-yellow2",
    textColor3: "text-yellow3",
    textColor4: "text-yellow5",
    borderColor: "border-green6",
  },
  noon: {
    bgColor: "bg-teal4",
    bgColor2: "bg-teal5",
    bgColor3: "bg-green6",
    bgColor4: "bg-green14",
    bgColor5: "bg-green15",
    bgColor6: "bg-lightGreen6",
    bgColor7: "bg-green16",
    bgColor8: "bg-green17",
    textColor: "text-teal4",
    textColor2: "text-green14",
    textColor3: "text-green6",
    textColor4: "text-green16",
    borderColor: "border-teal4",
  },
  night: {
    bgColor: "bg-blue1",
    bgColor2: "bg-blue5",
    bgColor3: "bg-blue6",
    bgColor4: "bg-blue1",
    bgColor5: "bg-blue7",
    bgColor6: "bg-blue8",
    bgColor7: "bg-blue9",
    bgColor8: "bg-blue10",
    textColor: "text-blue1",
    textColor2: "text-blue1",
    textColor3: "text-blue6",
    textColor4: "text-blue9",
    borderColor: "border-blue1",
  },
  standBy: {
    bgColor: "bg-secondary",
    bgColor2: "",
    bgColor3: "",
    bgColor4: "",
    bgColor5: "",
    bgColor6: "",
    bgColor7: "",
    bgColor8: "",
    textColor: "text-white",
    textColor2: "",
    textColor3: "",
    textColor4: "",
    borderColor: "border-secondary",
  },
  dayOff: {
    bgColor: "bg-lightGreen2",
    bgColor2: "",
    bgColor3: "",
    bgColor4: "",
    bgColor5: "",
    bgColor6: "",
    bgColor7: "",
    bgColor8: "",
    textColor: "text-white",
    textColor2: "",
    textColor3: "",
    textColor4: "",
    borderColor: "border-lightGreen2",
  },
};

// Table Page

export const ROW_HEIGHT = 45;

export const ROW_WIDTH = 135;

export const SHIFT_TYPE: Record<
  "standBy" | "onCall",
  { label: string; colorCode: string }
> = {
  standBy: { label: "SB", colorCode: "#1971C2" },
  onCall: { label: "OC", colorCode: "#F08C00" },
};

export const TIMING_LIST: {
  label: string;
  key: Key;
  type: ShiftType;
  time?: {
    from: { hour: number; min?: number };
    to: { hour: number; min?: number };
  }[];
}[] = [
  {
    key: "morning",
    label: "Morning",
    type: "normal",
    time: [{ from: { hour: 6 }, to: { hour: 11, min: 59 } }],
  },
  {
    key: "noon",
    label: "Noon",
    type: "normal",
    time: [{ from: { hour: 12 }, to: { hour: 17, min: 59 } }],
  },
  {
    key: "night",
    label: "Night",
    type: "normal",
    time: [
      {
        from: { hour: 18 },
        to: { hour: 23, min: 59 },
      },
      {
        from: { hour: 0 },
        to: { hour: 5, min: 59 },
      },
    ],
  },
  {
    key: "standBy",
    label: "Stand\nBy",
    type: "standBy",
  },
];
