import { GROUP_COLORS, SHIFT_TYPE, TIME_ZONE } from "@/constants";
import { Shift } from "@/interface/shift";
import CarotTwo from "../Icons/CarotTwo";
import { getTimeBlock } from "@/utils/getTimeBlock";
import { formatTimeWSpace } from "@/utils/formatDate";
import { DateTime } from "luxon";
import TimeView from "./TimeView";

const ShiftCell = ({
  isActive,
  setIsTimeShow,
  shift,
  slotIndex,
  isMultiple,
  isTimeShow,
  onNextShift,
  onPrevShift,
}: {
  isActive: boolean;
  setIsTimeShow: React.Dispatch<React.SetStateAction<boolean>>;
  shift: Shift;
  slotIndex: number;
  isMultiple: boolean;
  isTimeShow: boolean;
  onNextShift: () => void;
  onPrevShift: () => void;
}) => {
  const timeBlock = getTimeBlock(shift.time.from);

  const [timeFrom, amPmFrom] = formatTimeWSpace(
    DateTime.fromISO(shift.time.from).setZone(TIME_ZONE)
  ).split(" ");
  const [timeTo, amPmTo] = formatTimeWSpace(
    DateTime.fromISO(shift.time.to).setZone(TIME_ZONE)
  ).split(" ");

  return (
    <>
      {(shift.slots[slotIndex].labels?.length > 0 ||
        shift.type !== "normal") && (
        <div className="absolute left-0 top-4 flex flex-col">
          <div
            className="rounded-r-[4px] p-[3px] text-white text-[8px] leading-none font-medium"
            style={{
              background:
                shift.type !== "normal"
                  ? SHIFT_TYPE[shift.type as "standBy" | "onCall"].colorCode
                  : shift.slots[slotIndex].labels[0].colorCode,
            }}
          >
            {shift.type !== "normal"
              ? SHIFT_TYPE[shift.type as "standBy" | "onCall"].label
              : shift.slots[slotIndex].labels[0].shortLabel}
          </div>
          {((shift.type === "normal" &&
            shift?.slots[slotIndex]?.labels?.length > 1) ||
            (shift.type !== "normal" &&
              shift.slots[slotIndex]?.labels?.length > 0)) && (
            <div
              className={
                "text-maroon1 text-[8px] leading-none font-medium text-center"
              }
            >
              {shift.type === "normal"
                ? shift.slots[slotIndex].labels.length - 1
                : shift.slots[slotIndex].labels.length}
              +
            </div>
          )}
        </div>
      )}
      <div className="flex items-center">
        {isMultiple && (
          <div className="cursor-pointer pr-1" onClick={onPrevShift}>
            <CarotTwo
              type="left"
              pathFill={"#E9E9E9"}
              size={{ width: "5px", height: "8px" }}
            />
          </div>
        )}
        {isTimeShow ? (
          <TimeView
            key={shift._id}
            isActive={isActive}
            timeFrom={timeFrom}
            timeBlock={timeBlock}
            amPmFrom={amPmFrom}
            timeTo={timeTo}
            amPmTo={amPmTo}
          />
        ) : (
          <div
            className={`rounded-lg transition-all ${GROUP_COLORS[timeBlock].bgColor3} flex drop-shadow-xl`}
          >
            <div className="rounded-lg bg-white flex">
              <div
                className={`rounded-lg min-w-[42px] ${
                  isActive
                    ? `${GROUP_COLORS[timeBlock].bgColor3} text-white`
                    : "bg-gray11 text-black4"
                } h-fit font-medium text-sm p-1 text-center`}
              >
                {shift.location.shortLabel}
              </div>
              <div
                className="h-full trasition-[width] duration-300"
                style={{ width: isActive ? "0px" : "2px" }}
              ></div>
            </div>
            <div
              className="h-full trasition-[width] duration-300"
              style={{ width: isActive ? "0px" : "4px" }}
            ></div>
          </div>
        )}
        {isMultiple && (
          <div className="cursor-pointer pl-1" onClick={onNextShift}>
            <CarotTwo
              type="right"
              pathFill={"#E9E9E9"}
              size={{ width: "5px", height: "8px" }}
            />
          </div>
        )}
      </div>
      {isTimeShow ? (
        <div
          className="absolute right-0 h-full flex items-center cursor-pointer"
          onClick={() => {
            setIsTimeShow(false);
          }}
        >
          <div
            className={`bg-secondary text-white font-medium vertical-text -scale-100 text-[10px] rounded-r-md py-1 pr-[2px]`}
          >
            {shift.location.shortLabel}
          </div>
        </div>
      ) : (
        <div
          className="absolute right-0 bottom-0 pb-[0.5px] cursor-pointer"
          onClick={() => {
            setIsTimeShow(true);
          }}
        >
          <div
            className={`${GROUP_COLORS[timeBlock].textColor2} flex flex-row-reverse pr-[1px]`}
          >
            {timeFrom.split(":").length > 1 && (
              <div className="cursor-pointer font-medium text-xs h-fit leading-none relative bottom-[3px]">
                +
              </div>
            )}
            <div className={`font-medium leading-none h-fit`}>
              {timeFrom.length === 1 ? timeFrom : timeFrom.split(":")[0]}
            </div>
          </div>
          <div className="flex flex-row-reverse">
            <div
              className={`rounded-l-sm ${GROUP_COLORS[timeBlock].bgColor4} px-[3px] py-[1px] text-white text-[8px] leading-none font-medium w-fit`}
            >
              {amPmFrom}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShiftCell;
