import { useCallback, useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";

import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import { ROW_HEIGHT, ROW_WIDTH, TIME_ZONE } from "@/constants";
import { isDatesEqual } from "@/utils/date";

import Cell from "./Cell";
import { Doctor } from "@/interface/doctor";
import { useDoctors, useTableData } from "./data";
import { Shift } from "@/interface/shift";
import { Schedule } from "@/interface/schedule";
import { formatDateAPI, formatMonthYearKey } from "@/utils/formatDate";
import { useSeniority } from "@/store/seniority.state";
import { useLocation } from "@/store/location.store";
import { InfinitySpin } from "react-loader-spinner";
import { useGetDates } from "@/hooks/table/useGetDates";
import { SetTableParamsArgs } from "@/context/TableViewContext";

// Add react window along with infinite scroll

const Table = () => {
  const {
    month,
    year,
    activeDate,
    setActiveDate,
    isShiftTimeToggle,
    scrollRef,
    onScrollHandlers,
    isDayOffFilter,
    isLocationFilter,
    monthRef,
    monthLabel,
    setMonthLabel,
    setTableParams,
    calenderBack,
    calenderNext,
  } = useTableViewContext();

  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniorityId } = useSeniority();

  const getIsActiveHeader = ({ date }: { date: Date }) => {
    return (
      Boolean(!activeDate) || (activeDate && isDatesEqual(date, activeDate))
    );
  };

  const getIsActiveCell = ({
    date,
    locationIds,
    isDayOff,
    calendarData,
    noShiftsAssigned,
  }: {
    date: Date;
    locationIds: string[];
    isDayOff: boolean;
    calendarData: any;
    noShiftsAssigned: boolean;
  }) => {
    if (activeDate) {
      return isDatesEqual(date, activeDate);
    }

    if (isDayOffFilter && isLocationFilter && !activeLocationId) {
      return true;
    }

    if (isDayOffFilter || isLocationFilter) {
      return (
        (isDayOffFilter &&
          (isDayOff || (calendarData?.isFinalized && noShiftsAssigned))) ||
        (isLocationFilter &&
          ((!activeLocationId && locationIds.length > 0) ||
            locationIds.includes(activeLocationId)))
      );
    }

    return Boolean(!activeDate);
  };

  const { doctors, isLoading: isDoctorsLoading } = useDoctors();

  const [type, setType] = useState<"increment" | "decrement">("decrement");

  const {
    schedulesByDoctor,
    shiftsByMonth,
    dayOffsByMonth,
    calendar,
    isTableDataFetching,
    isTableDataLoading,
  } = useTableData({ month, year, type });

  const getCalendarData = useCallback(
    (date: Date) => {
      if (activeLocationId && activeSeniorityId) {
        return calendar?.[
          DateTime.fromJSDate(date).setZone(TIME_ZONE).toUTC().toISO()!
        ];
      } else {
        return null;
      }
    },
    [activeLocationId, activeSeniorityId, calendar]
  );

  const tableRef = useRef<HTMLDivElement | null>(null);

  const overlap = tableRef.current
    ? Math.ceil(tableRef.current.clientWidth / ROW_WIDTH)
    : 9;

  const { dates, monthData } = useGetDates({
    month,
    year,
    overlap,
  });

  const horizontScroll = useRef<{
    calenderBack: () => { month: number; year: number };
    calendarNext: () => {
      month: number;
      year: number;
    };
    setTableParams: (args: SetTableParamsArgs) => void;
    isRunOnce: boolean;
    datesLength: number;
    monthData: {
      prev: { daysInMonth: number; label: string };
      current: { daysInMonth: number; label: string };
    };
    overlap: number;
    type: "increment" | "decrement";
    monthLabel: {
      animated: {
        type: "next" | "current" | "prev";
        hasArrows: boolean;
        show: boolean;
      };
      stationary: {
        type: "next" | "current" | "prev";
        hasArrows: boolean;
        show: boolean;
      };
    };
  }>({
    calenderBack: calenderBack,
    calendarNext: calenderNext,
    setTableParams: setTableParams,
    isRunOnce: false,
    datesLength: dates.length,
    monthData,
    overlap: overlap,
    type: "decrement",
    monthLabel,
  });

  horizontScroll.current.calendarNext = calenderNext;
  horizontScroll.current.calenderBack = calenderBack;
  horizontScroll.current.setTableParams = setTableParams;
  horizontScroll.current.monthData = monthData;
  horizontScroll.current.overlap = overlap;
  horizontScroll.current.datesLength = dates.length;
  horizontScroll.current.type = type;
  horizontScroll.current.monthLabel = monthLabel;

  console.log(monthLabel);

  useEffect(() => {
    if (
      monthRef &&
      tableRef.current &&
      schedulesByDoctor &&
      shiftsByMonth &&
      dayOffsByMonth &&
      monthRef.current &&
      !horizontScroll.current.isRunOnce
    ) {
      tableRef.current.scrollLeft =
        (ROW_WIDTH + 2) * horizontScroll.current.overlap + 4;
      const handler = () => {
        if (
          tableRef.current &&
          monthRef.current.animated &&
          monthRef.current.stationary
        ) {
          const overlapLength =
            (ROW_WIDTH + 2) * horizontScroll.current.overlap + 4;
          const fullLength =
            (ROW_WIDTH + 2) * horizontScroll.current.datesLength;

          if (
            tableRef.current.scrollLeft >= 0 &&
            tableRef.current.scrollLeft < overlapLength
          ) {
            if (
              horizontScroll.current.monthLabel.animated.type !== "current" ||
              !horizontScroll.current.monthLabel.animated.show
            ) {
              setMonthLabel((mL) => ({
                ...mL,
                animated: { ...mL.animated, type: "current", show: true },
              }));
            }
            if (
              tableRef.current.scrollLeft >= 0 &&
              tableRef.current.scrollLeft <
                overlapLength -
                  (horizontScroll.current.monthData.prev.label.length * 45 + 88)
            ) {
              if (
                horizontScroll.current.monthLabel.animated.hasArrows ||
                horizontScroll.current.monthLabel.stationary.type !== "prev" ||
                !horizontScroll.current.monthLabel.stationary.show
              ) {
                setMonthLabel((mL) => ({
                  stationary: { ...mL.stationary, type: "prev", show: true },
                  animated: { ...mL.animated, hasArrows: false },
                }));
              }
            } else {
              if (
                !horizontScroll.current.monthLabel.animated.hasArrows ||
                horizontScroll.current.monthLabel.stationary.type !==
                  "current" ||
                horizontScroll.current.monthLabel.stationary.show
              ) {
                setMonthLabel((mL) => ({
                  stationary: {
                    ...mL.stationary,
                    type: "current",
                    show: false,
                  },
                  animated: { ...mL.animated, hasArrows: true },
                }));
              }
            }
            monthRef.current.animated.style.left = `${
              -1 * (tableRef.current.scrollLeft - overlapLength)
            }px`;
          }

          if (
            tableRef.current.scrollLeft >= overlapLength &&
            tableRef.current.scrollLeft <
              fullLength - overlapLength - tableRef.current.clientWidth
          ) {
            if (
              horizontScroll.current.monthLabel.stationary.type !== "current" ||
              !horizontScroll.current.monthLabel.stationary.show ||
              horizontScroll.current.monthLabel.animated.show
            ) {
              setMonthLabel((mL) => ({
                animated: { ...mL.animated, show: false },
                stationary: { ...mL.stationary, type: "current", show: true },
              }));
            }
          }

          if (
            tableRef.current.scrollLeft >=
              fullLength - overlapLength - tableRef.current.clientWidth &&
            tableRef.current.scrollLeft <
              fullLength - tableRef.current.clientWidth
          ) {
            if (
              horizontScroll.current.monthLabel.animated.type !== "next" ||
              !horizontScroll.current.monthLabel.animated.show
            ) {
              setMonthLabel((mL) => ({
                ...mL,
                animated: { ...mL.animated, type: "next", show: true },
              }));
            }

            if (
              tableRef.current.scrollLeft >=
                fullLength -
                  tableRef.current.clientWidth -
                  (horizontScroll.current.monthData.current.label.length * 45 +
                    110) &&
              tableRef.current.scrollLeft <
                fullLength - tableRef.current.clientWidth
            ) {
              if (horizontScroll.current.monthLabel.stationary.show) {
                setMonthLabel((mL) => ({
                  ...mL,
                  stationary: { ...mL.stationary, show: false },
                }));
              }
            } else {
              if (!horizontScroll.current.monthLabel.stationary.show) {
                setMonthLabel((mL) => ({
                  ...mL,
                  stationary: { ...mL.stationary, show: true },
                }));
              }
            }

            monthRef.current.animated.style.left = `${
              fullLength - overlapLength - tableRef.current.scrollLeft
            }px`;
          }

          if (
            tableRef.current.scrollLeft <
              (fullLength - tableRef.current.clientWidth) / 2 &&
            horizontScroll.current.type === "increment"
          ) {
            setType("decrement");
          }
          if (
            tableRef.current.scrollLeft >
              (fullLength - tableRef.current.clientWidth) / 2 &&
            horizontScroll.current.type === "decrement"
          ) {
            setType("increment");
          }
          if (tableRef.current && tableRef.current.scrollLeft < 1) {
            tableRef.current.style.overflow = "hidden";
            const { month, year } = horizontScroll.current.calenderBack();
            horizontScroll.current.setTableParams({
              month_: month,
              year_: year,
            });
            setType("increment");
            tableRef.current.scrollLeft =
              (ROW_WIDTH + 2) *
              horizontScroll.current.monthData.prev.daysInMonth;

            setMonthLabel({
              animated: { type: "current", hasArrows: true, show: false },
              stationary: { type: "current", hasArrows: true, show: true },
            });

            setTimeout(() => {
              tableRef.current!.style.overflow = "";
            }, 300);
          } else if (
            tableRef.current.scrollLeft >=
            fullLength - tableRef.current.clientWidth
          ) {
            tableRef.current.style.overflow = "hidden";
            setType("decrement");
            const { month, year } = horizontScroll.current.calendarNext();
            horizontScroll.current.setTableParams({
              month_: month,
              year_: year,
            });
            tableRef.current.scrollLeft = overlapLength;

            setMonthLabel({
              animated: { type: "current", hasArrows: true, show: false },
              stationary: { type: "current", hasArrows: true, show: true },
            });

            setTimeout(() => {
              tableRef.current!.style.overflow = "";
            }, 300);
          }
        }
      };
      tableRef.current.addEventListener("scroll", handler);
      horizontScroll.current.isRunOnce = true;
    }
  }, [dayOffsByMonth, schedulesByDoctor, shiftsByMonth, monthRef]);

  useEffect(() => {
    if (
      scrollRef &&
      scrollRef.current.divTable &&
      schedulesByDoctor &&
      shiftsByMonth &&
      dayOffsByMonth
    ) {
      scrollRef.current.divTable.addEventListener(
        "scroll",
        onScrollHandlers.table
      );
      const elemCopy = scrollRef!.current.divTable;
      return () => {
        elemCopy?.removeEventListener("scroll", onScrollHandlers.table);
      };
    }
  }, [dayOffsByMonth, schedulesByDoctor, shiftsByMonth]);

  const getIsDayOff = (doctor: Doctor, date: Date) => {
    return (
      dayOffsByMonth &&
      Boolean(
        dayOffsByMonth[formatDateAPI(date)]?.doctors?.includes(doctor._id)
      )
    );
  };

  const getActiveColorHeader = (dateTime: DateTime) => {
    if (dateTime.month % 2 === 0) {
      return dateTime.weekday === 6 || dateTime.weekday === 7
        ? "bg-green3"
        : "bg-pink1";
    } else {
      return dateTime.weekday === 6 || dateTime.weekday === 7
        ? "bg-teal7"
        : "bg-maroon4";
    }
  };

  return (
    <div
      className="w-full overflow-x-scroll h-full bg-gray4 small-scrollbar-y"
      ref={tableRef}
    >
      {!isDoctorsLoading && !isTableDataLoading ? (
        <table className="table-fixed border-spacing-x-[2px] border-separate h-full">
          <thead>
            <tr className="block">
              {dates.map((date, index) => {
                const dateTime = DateTime.fromJSDate(date).setZone(TIME_ZONE);
                return (
                  <th key={index}>
                    <div
                      className={`${
                        getIsActiveHeader({ date })
                          ? getActiveColorHeader(dateTime)
                          : "bg-gray7"
                      } rounded-t-lg w-[${ROW_WIDTH}px] h-[${ROW_HEIGHT}px] flex justify-center items-center relative text-white cursor-pointer font-normal`}
                      onClick={() => {
                        if (activeDate && isDatesEqual(date, activeDate)) {
                          setActiveDate(undefined);
                          setTableParams({ activeDate_: null });
                        } else {
                          setActiveDate(date);
                          setTableParams({ activeDate_: date });
                        }
                      }}
                    >
                      <div className="absolute left-1 top-0 text-sm">
                        {dateTime.toFormat("MMM").toUpperCase()}
                      </div>
                      <div className="text-3xl">{dateTime.day}</div>
                      <div className="vertical-text -scale-100 absolute right-0 text-lg">
                        {dateTime.toFormat("EEE")}
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody
            className="block overflow-y-scroll h-full small-scrollbar"
            ref={(ref) => {
              scrollRef!.current.divTable = ref;
            }}
          >
            {doctors?.map((doctor: Doctor, index: number) => {
              return (
                <tr className="table" key={doctor._id}>
                  {dates.map((date, indexInner) => {
                    const scheduleIds: string[] = [];

                    const isDayOff = getIsDayOff(doctor, date);
                    const shifts: Shift[] = isDayOff
                      ? []
                      : shiftsByMonth?.[formatDateAPI(date)]?.filter(
                          (shift: Shift) => {
                            const schedule: Schedule | undefined =
                              schedulesByDoctor?.[formatMonthYearKey(date)]?.[
                                doctor._id
                              ]?.find(
                                (schedule: Schedule) =>
                                  shift._id === schedule.shift
                              );
                            if (schedule) {
                              scheduleIds.push(schedule._id);
                            }
                            return Boolean(
                              schedule &&
                                (shift.seniority.id === activeSeniorityId ||
                                  !activeSeniorityId)
                            );
                          }
                        ) ?? [];

                    const calendarData = getCalendarData(date);

                    return (
                      <td key={indexInner}>
                        <div
                          className={`w-[${ROW_WIDTH}px] h-[${ROW_HEIGHT}px] border-b-green12 border-b-[1px]`}
                        >
                          <Cell
                            isFetching={isTableDataFetching}
                            isActive={getIsActiveCell({
                              date,
                              locationIds: shifts.map(
                                (shift) => shift.location._id
                              ),
                              isDayOff,
                              calendarData,
                              noShiftsAssigned: shifts.length === 0,
                            })}
                            shifts={shifts}
                            slotIndexes={shifts.map((shift) =>
                              shift.slots.findIndex((slot) =>
                                scheduleIds.includes(slot.schedule)
                              )
                            )}
                            isOff={
                              calendarData?.isFinalized && shifts.length === 0
                                ? true
                                : isDayOff
                            }
                            isTimeShowExt={isShiftTimeToggle}
                          />
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr
              className="table"
              style={{
                height: `calc(100% - ${ROW_HEIGHT * doctors?.length}px)`,
              }}
            >
              {dates.map((date, index) => {
                return (
                  <td className="h-full" key={index}>
                    <div
                      className={`${
                        getIsActiveHeader({ date }) ? "bg-white" : "bg-gray8"
                      } w-[135px] h-full`}
                    ></div>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      ) : (
        <div className="h-full flex justify-center items-center w-[80vw]">
          <div>
            <InfinitySpin width="200px" color="#67823A" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
