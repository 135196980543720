import { GROUP_COLORS } from "@/constants";
import { TimeBlock } from "../Dashboard/DayView";
import { useEffect, useRef, useState } from "react";

const TimeView = ({
  isActive,
  timeFrom,
  amPmFrom,
  timeTo,
  amPmTo,
  timeBlock,
}: {
  isActive: boolean;
  timeFrom: string;
  amPmFrom: string;
  timeTo: string;
  amPmTo: string;
  timeBlock: TimeBlock;
}) => {
  const timeRef = useRef<HTMLDivElement | null>(null);
  const [triggerRender, setTriggerRender] = useState(false);
  const renderCount = useRef<number>(-1);

  useEffect(() => {
    if (renderCount.current === -1) {
      renderCount.current = 0;
      setTriggerRender(!triggerRender);
    }
    if (renderCount.current !== -1 && renderCount.current < 1) {
      setTriggerRender(!triggerRender);
      renderCount.current += 1;
    } else if (renderCount.current === 1) {
      renderCount.current = -1;
    }
  }, [triggerRender]);

  return (
    <div
      className="relative rounded-lg drop-shadow-2xl bg-white"
      style={{
        width: timeRef.current?.offsetWidth
          ? timeRef.current.offsetWidth + 5
          : 60,
        height: timeRef.current?.offsetHeight ?? 40,
      }}
    >
      <div
        className={`relative rounded-t-lg rounded-bl-lg h-[75%] ${
          isActive
            ? GROUP_COLORS[timeBlock].bgColor3
            : GROUP_COLORS[timeBlock].bgColor7
        }`}
      ></div>
      <div
        className="flex gap-[1px] h-[37px] pt-[4px] rounded-lg absolute right-0 bottom-0"
        ref={timeRef}
      >
        <div className="flex flex-col justify-between ml-[5px]">
          <div
            className={`rounded-lg ${
              isActive ? "text-white" : "text-black5"
            } text-[21px] font-semibold leading-none`}
          >
            {timeFrom.split(":")[0]}
          </div>
          <div
            className={`${GROUP_COLORS[timeBlock].textColor2} text-[6px] font-semibold h-fit leading-none flex flex-row-reverse`}
          >
            TO
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <div className="flex font-extrabold gap-[2px] pr-[4px]">
            {timeFrom.split(":")[1] && (
              <div
                className={`text-[11px] ${
                  isActive ? "text-white" : "text-black5"
                } relative bottom-0.5`}
              >
                :{timeFrom.split(":")[1]}
              </div>
            )}
            <div
              className={`text-[7px] ${
                GROUP_COLORS[timeBlock].textColor3
              } font-bold ${
                isActive ? "bg-white" : "bg-black5"
              } leading-none px-[3.5px] py-[1px] rounded-[4px] h-fit flex items-center relative top-[1.5px]`}
            >
              {amPmFrom}
            </div>
          </div>
          <div
            className={`${
              isActive
                ? GROUP_COLORS[timeBlock].bgColor6
                : GROUP_COLORS[timeBlock].bgColor8
            } rounded-tl-lg rounded-br-lg text-[11px] justify-center leading-none font-medium ${
              isActive ? GROUP_COLORS[timeBlock].textColor2 : "text-white2"
            } flex flex-nowrap items-center px-1 pt-[2px] pb-[1px] h-fit`}
          >
            {timeTo}
            <div className="text-[8px] relative top-[1px]">{amPmTo}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeView;
