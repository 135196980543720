import { useEffect, useState } from "react";
import { InfinitySpin } from "react-loader-spinner";

import { ROW_HEIGHT, SENIORITIES } from "@/constants";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import { useSeniority } from "@/store/seniority.state";
import { Doctor } from "@/interface/doctor";

import { useDoctors } from "./data";

// import DoctorCard from "../Cards/SmallDoctorCard";
import SearchTwo from "../Icons/SearchTwo";
import Sort from "../Icons/Sort";
import DoctorCard from "./DoctorCard";
import debouncer from "@/utils/debouncer";

const SideBar = () => {
  const {
    scrollRef,
    onScrollHandlers,
    doctorSort,
    setDoctorSort,
    searchQuery,
    setSearchQuery,
    setTableParams,
  } = useTableViewContext();

  const { activeId: activeSeniorityId, setActiveId } = useSeniority();

  const [_, setTriggerRender] = useState(false);

  const {
    doctors,
    isLoading: isDoctorsLoading,
    isFetching: isDoctorsFetching,
  } = useDoctors();

  useEffect(() => {
    if (scrollRef && scrollRef.current.divSideBar) {
      const func = debouncer(50, () => setTriggerRender((tR) => !tR));
      const scrollHandler = () => {
        func();
        onScrollHandlers.sideBar();
      };

      scrollRef.current.divSideBar.addEventListener("scroll", scrollHandler);
      const elemCopy = scrollRef!.current.divSideBar;

      return () => {
        elemCopy?.removeEventListener("scroll", scrollHandler);
      };
    }
  }, [onScrollHandlers.sideBar, scrollRef]);

  return (
    <div className="w-full bg-gray8 h-full flex flex-col">
      <div className={`h-[${ROW_HEIGHT}px]`}>
        <div className="h-[40%] w-full bg-gray4 flex">
          <div className="flex gap-[2px] h-full px-1">
            {SENIORITIES.map((seniority) => (
              <div className="flex flex-col-reverse" key={seniority.id}>
                <div
                  className={`bg-[${seniority.colorCode}] rounded-t-lg ${
                    activeSeniorityId === seniority.id
                      ? "text-sm text-nowrap h-fit pt-1 px-1 pb-[2px]"
                      : "text-xs h-full p-1"
                  } font-medium text-white cursor-pointer`}
                  onClick={() => {
                    setActiveId(seniority.id);
                    setTableParams({ seniority: seniority.id });
                  }}
                >
                  {activeSeniorityId === seniority.id
                    ? seniority.label
                    : seniority.shortLabel}
                </div>
              </div>
            ))}
            <div className="flex flex-col-reverse">
              <div
                className={`bg-black rounded-t-lg ${
                  activeSeniorityId === undefined
                    ? "text-sm h-fit pt-1 px-1 pb-[2px]"
                    : "text-xs h-full p-1"
                } font-medium text-white cursor-pointer`}
                onClick={() => {
                  setActiveId(undefined);
                  setTableParams({ seniority: null });
                }}
              >
                All
              </div>
            </div>
          </div>
          <div className="bg-gray8 rounded-t-lg flex-grow px-[2px] pt-[2px] h-full">
            <div className="bg-white w-full rounded-l-full rounded-r-full px-1 relative flex h-full items-center">
              <input
                placeholder="Search"
                className="bg-white w-full outline-none h-full text-[9px] font-medium"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
              <div className="absolute right-1 top-[2px]">
                <SearchTwo />
              </div>
            </div>
          </div>
        </div>
        <div className="h-[60%] flex items-center px-2">
          <div
            className="p-[2px] bg-white rounded-md flex justify-center items-center shadow-xl cursor-pointer"
            onClick={() => {
              setDoctorSort(doctorSort === "asc" ? "desc" : "asc");
            }}
          >
            <Sort />
          </div>
        </div>
      </div>
      <div
        className={`overflow-y-auto px-1 flex-grow h-0 no-scrollbar ${
          isDoctorsFetching ? "animate-pulseFast" : ""
        }`}
        ref={(ref) => {
          scrollRef!.current.divSideBar = ref;
        }}
      >
        {isDoctorsLoading ? (
          <InfinitySpin width="200px" color="#67823A" />
        ) : (
          doctors?.map((doctor: Doctor, index: number) => (
            <div
              className={`h-[${ROW_HEIGHT}px] flex items-center`}
              key={doctor._id}
            >
              <div className="h-[90%] w-full">
                <DoctorCard
                  doctorId={doctor._id}
                  name={doctor.user.name}
                  seniority={doctor.seniority.id}
                  nickName={doctor.user.nickName}
                  pos={(() => {
                    if (
                      index <
                      Math.ceil(
                        ((scrollRef!.current.divSideBar?.clientHeight ?? 1000) +
                          (scrollRef!.current.divSideBar?.scrollTop ?? 1000)) /
                          ROW_HEIGHT
                      ) -
                        Math.ceil(360 / ROW_HEIGHT)
                    )
                      return "top";
                    return "bottom";
                  })()}
                />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SideBar;
