const Sort = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
    >
      <path
        d="M0.444397 10.4513L2.83502 4.07629H4.20565L6.59627 10.4513H5.2894L4.74752 8.92129H2.29315L1.78315 10.4513H0.444397ZM2.67565 7.83754H4.33315L3.56815 5.44692H3.47252L2.67565 7.83754ZM7.90315 10.4513V9.24004L11.1225 5.22379H8.03065V4.07629H12.525V5.28754L9.33752 9.30379H12.5569V10.4513H7.90315ZM4.9069 2.80129L6.8194 0.888794L8.7319 2.80129H4.9069ZM6.8194 13.6388L4.9069 11.7263H8.7319L6.8194 13.6388Z"
        fill="#DF9C28"
      />
    </svg>
  );
};

export default Sort;
